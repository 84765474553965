<template>
  <v-textarea
    outlined
    auto-grow
    :label="field.text"
    v-model="value"
    :hide-details="!field.editable"
    :disabled="!field.editable"
    :rules="validators"
    :placeholder="field.placeholder || '-'"
  />
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  computed: {
    value: {
      get () {
        const value = get(this.data, this.field.value, '')
        return value
      },
      set (value) {
        this.$emit('update', { [this.field.name]: value })
      }
    }
  }
}
</script>
